
export default [
  'כיסוי מינוס',
  'קניית רכב',
  'שיפוץ',
  'לימודים',
  'טיסה לחו״ל',
  'משכנתא',
  'אחר'
]
