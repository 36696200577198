import { SERVER_URL } from '../consts'

const preLoanApplication = async (preLoanData: Record<string, any>): Promise<any> => {

  try {
    const response = await fetch(`${SERVER_URL}/cav/preLoanApplication`, {
      method: 'POST',
      body: JSON.stringify(preLoanData)
    })

    const resData = await response.json()

    console.log(resData)
  }
  catch (e) {
    console.error('request to cav failed with error', e)
  }
}

export default preLoanApplication