/* eslint-disable max-len */
export const banks = [
  {
    _id: 'bankhapoalim',
    bankId: 'בנק הפועלים',
    site: 'www.bankhapoalim.co.il',
    image: 'https://open-finance-assets.s3.eu-west-1.amazonaws.com/providers-logos/hapolim.jpeg',
    number: '20',
  },
  {
    _id: 'b79bb5c9-eb30-4e5f-8615-02484ae0f5f4',
    bankId: 'בנק מזרחי-טפחות',
    site: 'www.mizrahi-tefahot.co.il',
    image: 'https://open-finance-assets.s3.eu-west-1.amazonaws.com/providers-logos/mizrahi.svg',
    number: '20',
  },
  // {
  //   _id: 'b79bb5c9-eb30-4e5f-8615-02484ae0f5f8',
  //   bankId: 'Open Finance Bank',
  //   site: 'www.open-finance.ai',
  //   image: 'https://open-finance-media.s3.eu-central-1.amazonaws.com/logos/transparent-logo-black.png',
  //   number: '20',
  // },
  {
    _id: 'discount',
    bankId: 'בנק דיסקונט',
    site: 'www.discountbank.co.il',
    image: 'https://open-finance-assets.s3.eu-west-1.amazonaws.com/providers-logos/discount.jpeg',
    number: '20',
  },
  {
    _id: 'fibi',
    bankId: 'בנק הבינאלומי',
    site: 'www.fibi.co.il',
    image: 'https://open-finance-assets.s3.eu-west-1.amazonaws.com/providers-logos/beinleumi.jpeg',
    number: '20',
  },
  {
    _id: 'mercantile',
    bankId: 'בנק מרכנתיל',
    site: 'www.mercantile.co.il',
    image: 'https://open-finance-assets.s3.eu-west-1.amazonaws.com/providers-logos/marcentil.jpeg',
    number: '20',
  },
  {
    _id: 'pepper',
    bankId: 'פפר',
    site: 'www.pepper.co.il',
    image: 'https://www.pepper.co.il/wp-content/themes/pepper/images/logo.svg',
    number: '20',
  },
  {
    _id: 'בנק ירושלים',
    bankId: 'Jerusalem',
    site: 'www.bankjerusalem.co.il',
    image: 'https://open-finance-assets.s3.eu-west-1.amazonaws.com/providers-logos/jerusalem.jpeg',
    number: '20',
  },
  {
    _id: 'yahav',
    bankId: 'בנק יהב',
    site: 'www.bank-yahav.co.il',
    image: 'https://open-finance-assets.s3.eu-west-1.amazonaws.com/providers-logos/yahav.jpeg',
    number: '20',
  },
  {
    _id: 'igud',
    bankId: 'בנק איגוד',
    site: 'www.unionbank.co.il',
    image: 'https://open-finance-assets.s3.eu-west-1.amazonaws.com/providers-logos/union.jpeg',
    number: '20',
  },
  {
    _id: 'digibank',
    bankId: 'בנק דיגיטלי',
    site: 'www.digibank.co.il/',
    image: 'https://www.digibank.co.il/Content/images/footer-logo.svg',
    number: '20',
  }
]