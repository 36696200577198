import React from 'react'
import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material'
import errorImg from '../assets/icons/error.png'
import saturn from '../assets/icons/saturn.png'
import asteroid from '../assets/icons/asteroid.png'
import ufo from '../assets/icons/ufo.png'
import astronaut from '../assets/icons/astronaut.png'
import Floaty from './generic/Floaty'

// types
type Props = {
  error: Error,
}

// style
const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    display: 'flex',
    width: '100vw',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    zIndex: 1,
  },
  img: {
    width: 160,
    '@media (min-width: 780px)': {
      width: 230,
    }
  },
  typography: {
    marginTop: spacing(),
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#646464',
    maxWidth: 400,
    '@media (min-width: 450px)': {
      maxWidth: 600,
    }
  },
  typographyContainer: {
    marginTop: spacing(2),
  },
}), { name: 'Error' })

const floaties = [
  {
    img: astronaut,
    data: {
      animation: 'float 40s linear infinite',
      top: '80%',
      left: '10%',
      animationDirection: 'reverse',
    }
  },
  {
    img: asteroid,
    data: {
      animation: 'float 35s linear infinite',
      top: '10%',
      left: '80%',
    }
  },
  {
    img: ufo,
    data: {
      animation: 'float-more 105s linear infinite',
      top: '8%',
      left: '74%',
      animationDirection: 'reverse',
    }
  },
  {
    img: saturn,
    data: {
      animation: 'float-more 80s linear infinite',
      top: '40%',
      left: '50%',
    }
  },
]

const Error = ({ error }: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {floaties.map(({ data, img }, i) => (<Floaty data={data} img={img} key={i} />))}
      <img className={classes.img} src={errorImg} alt="error" />
      <div className={classes.typographyContainer} >
        <div className={classes.typography}>
          Something went wrong... {error.message}
        </div>
        <div className={classes.typography}>
          Sorry about that. Please try to refreshing the page and contact us if the problem persists.
        </div>
      </div>
    </div>
  )
}

export default Error