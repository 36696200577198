import React from 'react'
import ProtectedRoute from '../../ProtectedRoute'

const Logs = (): JSX.Element => (
  <ProtectedRoute>
    <div>
      logs
    </div>
  </ProtectedRoute>
)

export default Logs