import React, { Dispatch, SetStateAction } from 'react'
import { IconButton, InputBase, styled } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: '#ededed',
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  height: '50px',
  display: 'flex',
  '&:hover': {
    boxShadow: 'inset 0px -8px 20px 0px rgb(197 197 197 / 30%)',
  },
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(2),
    width: 'auto',
  },
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const DeleteIconWrapper = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  height: '100%',
  paddingRight: theme.spacing(1)
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}))

interface Props {
  placeHolder: string,
  value: string,
  setValue: Dispatch<SetStateAction<string>>,
}

const SearchBar = (props: Props): JSX.Element => {

  const { placeHolder, value, setValue } = props

  return (
    <div>
      <Search>
        <SearchIconWrapper>
          <SearchIcon sx={{ color: 'rgba(0,0,0,0.30)' }} />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder={placeHolder}
          inputProps={{ 'aria-label': 'search' }}
          value={value}
          onChange={({ target }) => setValue(target.value)}
        />
        <DeleteIconWrapper onClick={() => setValue('')}>
          <CloseIcon fontSize="small" sx={{ color: 'rgba(0,0,0,0.50)' }} />
        </DeleteIconWrapper>
      </Search>
    </div >
  )
}

export default SearchBar
