/* eslint-disable max-len */
import React, { useCallback, useContext } from 'react'
import { makeStyles } from '@mui/styles'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Theme, Typography } from '@mui/material'
import CustomButton from '../../components/CustomButton'
import Plan from '../../components/generic/Plan'
import preLoanApplication from '../../api/preLoanApplication'
import getQueryStringValue from '../../utils/getQueryStringValue'
import { loanContext } from './LoanJourney'

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    textAlign: 'center',
    padding: spacing(2),
  },
}), { name: 'ChoosePlan' })

const ChoosePlan = (): JSX.Element => {
  const classes = useStyles()

  const {
    setStage,
    loanPlan,
    setLoanPlan,
    plans,
    loanAmount,
    isLoanConfirmed,
    nationalId,
    firstName,
    lastName,
    loanPeriod,
    birthday,
  } = useContext(loanContext)

  const handleChangePlan = useCallback(({ target }: SelectChangeEvent): void => {
    const chosenPlan = plans.find((plan) => plan.period === target.value)

    if (chosenPlan) {
      setLoanPlan(chosenPlan)
    }
  }, [plans, setLoanPlan])

  // eslint-disable-next-line require-await
  const handleClickContinue = useCallback(async (): Promise<void> => {
    const customerName = getQueryStringValue('customerName')

    if (customerName === 'clal') {
      const birthdayParse = birthday.toISOString().substr(0, 19).split('T')[0]

      const preLoanData = {
        ActualDate: '2021-04-04',
        AmitId: nationalId,
        CreditClientId: nationalId,
        FirstName: firstName,
        LastName: lastName,
        Gender: 1,
        LoanMonths: loanPeriod,
        LoanStartDate: '2021-04-04',
        SumRequired: loanAmount,
        accountName: 'איציק גיני',
        accountNumber: 50151147,
        bankCode: 10,
        branch: 944,
        dateOfBirth: birthdayParse,
      }

      await preLoanApplication(preLoanData)
    }

    setStage((prev) => prev + 1)
  }, [birthday, firstName, lastName, loanAmount, loanPeriod, nationalId, setStage])

  if (!loanPlan) {
    return <div>
      לא נמצאה תוכנית מתאימה
    </div>
  }

  return (
    <div className={`${classes.root} rtl`}>
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, padding: 10 }}>
        <Typography variant="h6" fontFamily="Rubik,sans-serif" fontWeight='bold'>
          ההלוואה שלך אושרה!
        </Typography>
        {isLoanConfirmed && <>
          <Typography fontFamily="Rubik,sans-serif" sx={{ fontSize: 14 }}>
            אושרה לך זכאות להלוואה בסך {(Number(loanAmount.replaceAll(',', '')) * 1.25).toLocaleString()}₪
          </Typography>
          <div onClick={() => setStage(0)}>
            <Typography fontFamily="Rubik,sans-serif" sx={{ fontSize: 14, color: 'blue', cursor: 'pointer' }} >
              לשינוי ההלוואה לחץ כאן
            </Typography>
          </div>
        </>}
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', width: '80%', alignSelf: 'center' }}>
        <FormControl fullWidth>
          <InputLabel id="payment-track-select-label">בחירת מסלול התשלומים</InputLabel>
          <Select
            labelId="payment-track-select-label"
            id="payment-track-select"
            value={loanPlan.period}
            label="בחירת מסלול התשלומים"
            onChange={handleChangePlan}
          >
            {
              plans.map((plan) => <MenuItem sx={{ placeContent: 'end' }} value={plan.period} key={plan.title}> {plan.title} - {plan.period}</MenuItem>)
            }
          </Select>
        </FormControl>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', flex: 4, paddingTop: 8 }}>
        <Plan plan={loanPlan} />
      </div>
      <CustomButton onClick={handleClickContinue}>
        אישור
      </CustomButton>
    </div>
  )
}

export default ChoosePlan
