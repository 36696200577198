/* eslint-disable max-len */
import React, { FormEvent, useContext, useRef, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { FormHelperText, InputAdornment, TextField, Theme, Typography, Button } from '@mui/material'
import AccountBox from '@mui/icons-material/Person'
import EmailIcon from '@mui/icons-material/Email'
import PhoneIcon from '@mui/icons-material/Phone'
import NationalIdIcon from '@mui/icons-material/Fingerprint'
import toast from 'react-hot-toast'
import { DatePicker, LocalizationProvider } from '@mui/lab'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import CustomButton from '../../components/CustomButton'
import isValidIsraeliID from '../../functions/isValidIsraeliId'
import { loanContext } from './LoanJourney'

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    textAlign: 'center',
    padding: spacing(3),
  },
  title: {
    // paddingBottom: spacing(1),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingBottom: spacing(2),
  },
  errorMessageContainer: {
    display: 'flex',
    flex: 1,
    alignSelf: 'end'
  },
  'customFileUpload': {
    border: '1px solid #ccc',
    display: 'inline - block',
    padding: '6px 12px',
    cursor: 'pointer',
  }
}), { name: 'PersonalDetails' })

const PersonalDetails = (): JSX.Element => {
  const classes = useStyles()

  const [selectedIdentityFile, setSelectedIdentityFile] = useState<any>()
  const [selectedMortgageContractFile, setSelectedMortgageContractFile] = useState<any>()
  const [selectedAppraiserEvaluationFile, setAppraiserEvaluationFile] = useState<any>()
  const inputIdentityFileRef = useRef<any>()
  const inputMortgageContractFileRef = useRef<any>()
  const inputAppraiserEvaluationFileRef = useRef<any>()

  const {
    setStage,
    errorMessage,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    birthday,
    setBirthday,
    email,
    setEmail,
    phone,
    setPhone,
    nationalId,
    setNationalId,
    loanType,
  } = useContext(loanContext)

  const handleClickContinue = (e: FormEvent): void => {
    e.preventDefault()

    // eslint-disable-next-line no-constant-condition
    if (isValidIsraeliID(nationalId) || true) {
      setStage((prev) => prev + 1)
    } else {
      toast.error('מספר ת.ז לא תקין')
    }
  }

  const handleChangeFile: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (event): void => {
    if (event && (event?.target as HTMLInputElement)?.files) {
      const files = (event?.target as HTMLInputElement)?.files

      if (files) {
        const targetName = event.target.name

        console.log(targetName)


        if (targetName === 'identity-file') {
          setSelectedIdentityFile(URL.createObjectURL(files[0]))
        }
        else if (targetName === 'mortgage-contract') {
          setSelectedMortgageContractFile(URL.createObjectURL(files[0]))
        }
        else if (targetName === 'appraiser-evaluation') {
          setAppraiserEvaluationFile(URL.createObjectURL(files[0]))
        }
      }
    }
  }

  return (
    <div className={`${classes.root} rtl`} >
      <Typography variant='h5' className={classes.title}>פרטים אישיים</Typography>
      <form className={classes.form} onSubmit={handleClickContinue}>
        <div style={{ display: 'flex' }}>
          <TextField
            sx={{ paddingLeft: 1 }}
            required
            value={firstName}
            onChange={(({ target }) => setFirstName(target.value))}
            label='שם פרטי'
            id="first-name"
            margin="dense"
            type='text'
            InputLabelProps={{
              sx: {
                textAlign: 'center'
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <AccountBox />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            required
            value={lastName}
            onChange={(({ target }) => setLastName(target.value))}
            label='שם משפחה'
            id="last-name"
            margin="dense"
            type='text'
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <AccountBox />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="תאריך לידה"
            value={birthday}
            onChange={(newValue) => {
              setBirthday(newValue)
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <TextField required value={email} onChange={(({ target }) => setEmail(target.value))}
          label='דוא״ל'
          id="email"
          margin="dense"
          type='email'
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <EmailIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField required value={phone} onChange={(({ target }) => setPhone(target.value))}
          label='טלפון נייד'
          id="phone"
          margin="dense"
          type='tel'
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <PhoneIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField required value={nationalId} onChange={(({ target }) => setNationalId(target.value))}
          label='תעודת זהות'
          id="national-id"
          margin="dense"
          type='text'
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <NationalIdIcon />
              </InputAdornment>
            ),
          }}
        />
        <div style={{ display: 'flex', width: '100%', flex: 1, height: 30, justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', paddingTop: 5, alignSelf: 'center', width: loanType === 'משכנתא' ? '33%' : '100%', justifyContent: 'center', alignContent: 'center' }}>
            {selectedIdentityFile ?
              <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                {/* <img src={selectedIdentityFile} width={140} onDoubleClick={() => setSelectedIdentityFile(null)} /> */}
                <Typography sx={{ fontSize: 12, color: 'green' }} onDoubleClick={() => setSelectedIdentityFile(null)}>
                  המסך המזהה הועלה בהצלחה
                </Typography>

              </div>
              : <div style={{ paddingTop: 10 }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => inputIdentityFileRef?.current?.click()}
                >
                  העלה מסמך מזהה
                  <input type="file" name='identity-file' onChange={handleChangeFile} ref={inputIdentityFileRef} style={{ display: 'none' }} />
                </Button>
              </div>
            }
          </div>
          {loanType === 'משכנתא' &&
            <>
              <div style={{ display: 'flex', paddingTop: 5, alignSelf: 'center', width: '33%' }}>
                {selectedMortgageContractFile ?
                  <div style={{ display: 'flex', justifyContent: 'center', flex: 1, }}>
                    <Typography sx={{ fontSize: 12, color: 'green' }} onDoubleClick={() => setSelectedMortgageContractFile(null)}>
                      חוזה המשכנתא הועלה בהצלחה
                    </Typography>
                  </div>
                  : <div style={{ paddingTop: 10 }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => inputMortgageContractFileRef?.current?.click()}
                    >
                      העלת חוזה המשכנתא
                      <input type="file" name='mortgage-contract' onChange={handleChangeFile} ref={inputMortgageContractFileRef} style={{ display: 'none' }} />
                    </Button>
                  </div>
                }
              </div>
              <div style={{ display: 'flex', paddingTop: 5, alignSelf: 'center', width: '33%' }}>
                {selectedAppraiserEvaluationFile ?
                  <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                    <Typography sx={{ fontSize: 12, color: 'green' }} onDoubleClick={() => setAppraiserEvaluationFile(null)}>
                      הערכת שמאי הועלה בהצלחה
                    </Typography>
                  </div>
                  : <div style={{ paddingTop: 10 }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => inputAppraiserEvaluationFileRef?.current?.click()}
                    >
                      העלת הערכת שמאי
                      <input type="file" name='appraiser-evaluation' onChange={handleChangeFile} ref={inputAppraiserEvaluationFileRef} style={{ display: 'none' }} />
                    </Button>
                  </div>
                }
              </div>
            </>
          }
        </div>
        <div className={classes.errorMessageContainer}>
          <FormHelperText error sx={{ height: 5 }}>
            {errorMessage}
          </FormHelperText>
        </div>
        {/* </FormControl> */}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <CustomButton /* onClick={handleClickContinue} */ type='submit'>
            המשך
          </CustomButton>
        </div>
      </form>

    </div>
  )
}

export default PersonalDetails
