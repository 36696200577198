import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(0,0,0)',
    },
    secondary: {
      main: 'rgb(34 193 195 / 72%)',
    },
    background: {
      default: 'transparent',
    },
  },
  typography: {
    fontFamily: [
      'Rubik',
      'sans-serif'
    ].join(','),
  },

})

export default theme