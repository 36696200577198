/* eslint-disable max-len */
import React, { useContext, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { FormControl, InputLabel, MenuItem, Select, Theme, Typography } from '@mui/material'
import creditCardTypes from '../../data/creditCardTypes'
import employmentTypes from '../../data/employmentTypes'
import CustomButton from '../../components/CustomButton'
import getQueryStringValue from '../../utils/getQueryStringValue'
import CustomSlider from '../../components/generic/CustomSlider'
import { loanContext } from './LoanJourney'

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    textAlign: 'center',
    padding: spacing(3),
  },
  title: {
    // paddingBottom: spacing(1),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingBottom: spacing(2),
    placeContent: 'space-between',
  },
  errorMessageContainer: {
    display: 'flex',
    flex: 1,
    alignSelf: 'end'
  },
  'customFileUpload': {
    border: '1px solid #ccc',
    display: 'inline - block',
    padding: '6px 12px',
    cursor: 'pointer',
  }
}), { name: 'EmploymentStatusDetails' })

const themeColor = getQueryStringValue('themeColor')

const EmploymentStatusDetails = (): JSX.Element => {
  const classes = useStyles()

  const {
    setStage,
  } = useContext(loanContext)

  const [creditCardType, setCreditCardType] = useState('')
  const [employmentStatus, setEmploymentStatus] = useState('')

  const getYearsValue = (value: any): string => {
    if (value === 0) {
      return 'פחות משנה'
    }
    else if (value === 12) {
      return 'שנה'
    }
    else if (value === 24) {
      return 'שנתיים'
    }
    else if (24 < value && value <= 120) {
      return `${value / 12} שנים`
    }
    else {
      return 'מעל 10 שנים'
    }
  }

  const getIncomeValue = (value: any): string => {
    if (value === 5000) {
      return 'עד 5,000 ש״ח'
    }
    else if (5000 < value && value < 50000) {
      return `${Number(value).toLocaleString()} ש״ח`
    }
    else {
      return 'מעל 50,000 ש״ח'
    }
  }

  const handleClickContinue = (): void => {
    setStage((prev) => prev + 1)
  }

  return (
    <div className={`${classes.root} rtl`} >
      <Typography variant='h6' className={classes.title} fontWeight='bold'>
        מצב תעסוקתי
      </Typography>
      <form className={classes.form} onSubmit={handleClickContinue}>
        <FormControl fullWidth margin='dense'>
          <InputLabel id='loan-type-select-label' >סוג כרטיס אשראי</InputLabel>
          <Select
            labelId="loan-type-select-label"
            value={creditCardType}
            label='סוג כרטיס אשראי'
            placeholder='סוג כרטיס אשראי'
            onChange={({ target }) => setCreditCardType(target.value)}
            sx={{ textAlign: 'right' }}
          >
            {
              creditCardTypes.map((type, index) => <MenuItem sx={{ placeContent: 'end' }} key={index} value={type}>{type}</MenuItem>)
            }
          </Select>
        </FormControl>
        <FormControl fullWidth >
          <InputLabel id='loan-type-select-label' >סטטוס תעסוקה</InputLabel>
          <Select
            labelId="loan-type-select-label"
            value={employmentStatus}
            label='סטטוס תעסוקה'
            placeholder='סטטוס תעסוקה'
            onChange={({ target }) => setEmploymentStatus(target.value)}
            sx={{ textAlign: 'right', marginBottom: 2 }}
          >
            {
              employmentTypes.map((type, index) => <MenuItem sx={{ placeContent: 'end' }} key={index} value={type}>{type}</MenuItem>)
            }
          </Select>
        </FormControl>
        <div>
          <Typography textAlign='right' sx={{ marginBottom: 4 }}>
            וותק בעבודה
          </Typography>
          <CustomSlider
            defaultValue={0}
            getAriaValueText={getYearsValue}
            valueLabelFormat={getYearsValue}
            valueLabelDisplay="on"
            step={12}
            // marks
            min={0}
            max={130}
            aria-labelledby="non-linear-slider"
            sx={{ width: 280, color: themeColor, alignSelf: 'center' }}
          />
        </div>
        <div>
          <Typography textAlign='right' sx={{ marginBottom: 4 }}>
            הכנסה חודשית
          </Typography>
          <CustomSlider
            defaultValue={5000}
            getAriaValueText={getIncomeValue}
            valueLabelFormat={getIncomeValue}
            valueLabelDisplay="on"
            step={500}
            // marks
            min={5000}
            max={50000}
            sx={{ width: 280, color: themeColor }}
          />
        </div>
        <div>
          <Typography textAlign='right' sx={{ marginBottom: 4 }}>
            מסגרת אשראי בחשבון הבנק
          </Typography>
          <CustomSlider
            defaultValue={5000}
            getAriaValueText={getIncomeValue}
            valueLabelFormat={getIncomeValue}
            valueLabelDisplay="on"
            step={500}
            // marks
            min={5000}
            max={50000}
            sx={{ width: 280, color: themeColor }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <CustomButton onClick={handleClickContinue} type='submit'>
            המשך
          </CustomButton>
        </div>
      </form>

    </div>
  )
}

export default EmploymentStatusDetails
