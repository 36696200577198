import { Divider, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { Plan as PlanType } from '../../@types'
import getQueryStringValue from '../../utils/getQueryStringValue'

interface Props {
  plan: PlanType
}

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: spacing(2),
  },
  titleContainer: {
    width: '80%',
    alignSelf: 'center',
    padding: spacing(2),
    backgroundColor: ({ themeColor }: any) => themeColor,
    opacity: 0.7,
    color: 'white',
  },
  monthlyPayment: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 22,
  },
  loanDetails: {
    width: '80%',
    alignSelf: 'center',
    padding: spacing(2),
    boxShadow: '9px 7px 20px 0px rgb(118 118 118 / 29%)',
  },
  textRow: {
    display: 'flex',
    placeContent: 'space-between',
    padding: 1,
  },
}), { name: 'Plan' })

const Plan = (props: Props): JSX.Element => {
  const themeColor = getQueryStringValue('themeColor')

  const classes = useStyles({ themeColor })

  const {
    plan: {
      title,
      monthlyPayment,
      period,
      loanAmount,
      TotalRepaymentAmount,
      serviceCommission,
      interest,
    }
  } = props


  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <Typography variant="h6" fontWeight="bolder">
          {title}
        </Typography>
      </div>
      <div className={classes.loanDetails}>
        <div className={classes.monthlyPayment}>
          <Typography variant="subtitle2">תשלום חודשי</Typography>
          <Typography variant="h4" fontWeight="bold">{monthlyPayment.toLocaleString()}₪</Typography>
          <Divider sx={{ width: '60%' }} />
        </div>
        <div className={classes.textRow}>
          <Typography variant="subtitle2">למשך</Typography>
          <Typography variant="subtitle2" fontWeight="bold">{period} חודשים</Typography>
        </div>
        <div className={classes.textRow}>
          <Typography variant="subtitle2">סכום ההלוואה</Typography>
          <Typography variant="subtitle2" fontWeight="bold">{loanAmount}₪</Typography>
        </div>
        <div className={classes.textRow}>
          <Typography variant="subtitle2">החזר כולל</Typography>
          <Typography variant="subtitle2" fontWeight="bold">{TotalRepaymentAmount}₪</Typography>
        </div>
        <div className={classes.textRow}>
          <Typography variant="subtitle2">עמלת שירות</Typography>
          <Typography variant="subtitle2" fontWeight="bold">{serviceCommission}₪</Typography>
        </div>
        <div className={classes.textRow}>
          <Typography variant="subtitle2">ריבית</Typography>
          <Typography variant="subtitle2" fontWeight="bold">{interest}%</Typography>
        </div>
      </div>
    </div>
  )

}

export default Plan
