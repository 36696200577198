import React from 'react'
import ProtectedRoute from '../../ProtectedRoute'


const Settings = (): JSX.Element => (
  <ProtectedRoute>
    <div>
      settings
    </div>
  </ProtectedRoute>
)

export default Settings