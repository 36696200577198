import React from 'react'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { Toaster } from 'react-hot-toast'
import MainRouter from './MainRouter'
import { theme } from './utils'

const AppProviders = (): JSX.Element => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MainRouter />
      <Toaster position='top-right' />
    </ThemeProvider>
  )
}

export default AppProviders