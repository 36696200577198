import React from 'react'
import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(({ spacing }: Theme) => ({
  dividerContainer: {
    display: 'flex',
    alignItems: 'center',
    opacity: 0.5,
    width: '100%',
    paddingBottom: spacing(1),
    paddingTop: spacing(1),
  },
  border: {
    opacity: 0.5,
    borderBottom: '1px solid black',
    width: '100%',
  },
  content: {
    padding: '0 10px 0 10px',
  },

}), { name: 'CustomDivider' })

interface Props {
  title: string
}

const CustomDivider = (props: Props): JSX.Element => {
  const classes = useStyles()

  const { title } = props

  return (
    <div className={classes.dividerContainer}>
      <div className={classes.border} />
      <span className={classes.content}>
        {title}
      </span>
      <div className={classes.border} />
    </div>
  )
}


export default CustomDivider
