/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { CircularProgress, FormControl, InputAdornment, TextField, Theme, Typography } from '@mui/material'
import Lock from '@mui/icons-material/Lock'
import CustomButton from '../../components/CustomButton'
import { Bank } from '../../@types'
import getQueryStringValue from '../../utils/getQueryStringValue'
import { loanContext } from './LoanJourney'

const lawUrl = 'https://www.tazkirim.gov.il/s/law-item/a093Y00001RdIaOQAV/%D7%AA%D7%96%D7%9B%D7%99%D7%A8-%D7%97%D7%95%D7%A7-%D7%9E%D7%AA%D7%9F-%D7%A9%D7%99%D7%A8%D7%95%D7%AA%D7%99-%D7%9E%D7%99%D7%93%D7%A2-%D7%A4%D7%99%D7%A0%D7%A0%D7%A1%D7%99-%D7%94%D7%AA%D7%A9%D7%A32020?language=iw'

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    textAlign: 'center',
    padding: spacing(3),
  },
  title: {
    paddingBottom: spacing(3),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  errorMessageContainer: {
    display: 'flex',
    flex: 1,
    alignSelf: 'end'
  },
  infoMsgContainer: {
    display: 'flex',
    backgroundColor: 'lightblue',
    // flex: 1,
    padding: 4,
    borderRadius: 8,
    marginTop: 12,
  },
}), { name: 'BankLogin' })

interface Props {
  bank: Bank
}

const BankLogin = (props: Props): JSX.Element => {
  const classes = useStyles()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [loaderText, setLoaderText] = useState('אנחנו מייבאים את המידע שלך')
  const [loaderText2, setLoaderText2] = useState('אנא המתן')

  const { bank } = props

  const {
    setStage,
    setIsLoanConfirmed,
  } = useContext(loanContext)

  const handleClickContinue = (): void => {
    setIsLoading(true)

    setTimeout(() => {
      setLoaderText('.הנתונים הובאו בהצלחה')
      setLoaderText2('זכאותך להלוואה נבדקת ברגעים אלו')
    }, 4000)

    setTimeout(() => {
      setStage((prev) => prev + 1)
      setIsLoanConfirmed(true)
      setIsLoading(false)
    }, 8000)
  }

  useEffect(() => {
    if (getQueryStringValue('short')) {
      handleClickContinue()
    }
  }, [])

  const themeColor = getQueryStringValue('themeColor')

  if (isLoading) {
    return <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Typography>
        {loaderText}
      </Typography>
      <Typography>
        {loaderText2}
      </Typography>
      <CircularProgress size={50} sx={{ margin: 3, color: themeColor }} />
    </div>
  }

  return (
    <div className={`${classes.root} rtl`}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img src={bank.image} alt='logo' width='80' />
      </div>
      <Typography variant='h5' className={classes.title}>התחברות ל{bank.bankId}</Typography>
      <Typography sx={{ fontSize: 15 }}>
        ע״י סיפוק פרטי ההתחברות שלך ל{bank.bankId}, אתה מאפשר לנו לייבא את המידע הפיננסי שלך
      </Typography>
      <form className={classes.form} onSubmit={handleClickContinue}>
        <FormControl sx={{ display: 'flex', flex: 2, marginTop: 2 }}>
          <TextField
            label='שם משתמש'
            value={username}
            onChange={({ target }) => setUsername(target.value)}
            required
            margin="normal"
            type='text'
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Lock />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label='סיסמא'
            value={password}
            onChange={({ target }) => setPassword(target.value)}
            required
            margin="normal"
            type='password'
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Lock />
                </InputAdornment>
              ),
            }}
          />
          <div className={classes.infoMsgContainer}>
            <img src="https://img.icons8.com/fluency/48/000000/info.png" height={35} style={{ display: 'flex', alignSelf: 'center' }} />
            <div>
              <Typography fontSize={13}>
                אתר זה מאובטח בהתאם לרישוי ואסדרה כחלק מחוק מתן שירותי מידע פיננסי (רישיון מס׳ 556),
              </Typography>
              <div onClick={() => window.open(lawUrl, '_blank')} style={{ cursor: 'pointer' }}>
                <Typography fontSize={13} color='blue'>
                  לעוד פרטים לחץ כאן
                </Typography>
              </div>
            </div>
          </div>
        </FormControl>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <CustomButton type='submit'>
            התחברות
          </CustomButton>
        </div>
      </form>
    </div>
  )
}

export default BankLogin
