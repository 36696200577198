import { makeStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  titleContainer: {
    fontSize: 13,
    fontWeight: 'bold',
  },
  rateValue: {
    width: '5rem',
    margin: '0.7rem auto 0 auto',
    padding: '0.3rem 0',
    background: 'transparent',
    border: '1px solid rgb(174 230 231)',
    borderRadius: '0.3rem',
    color: 'rgba(34,193,195,1)',
    fontWeight: 'bold',
  },
}), { name: 'Rate' })

interface Props {
  title: string
  subTitle: string
  value: string
}

const Rate = (props: Props): JSX.Element => {
  const classes = useStyles()

  const {
    title,
    subTitle,
    value
  } = props

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        {title}
        <br />
        {subTitle}
      </div>
      <p className={classes.rateValue}>
        {value}
      </p>
    </div>
  )
}

export default Rate