import { styled } from '@mui/system'
import { Slider } from '@mui/material'
import getQueryStringValue from '../../utils/getQueryStringValue'

const themeColor = getQueryStringValue('themeColor')

const CustomSlider = styled(Slider)({
  color: 'rgba(34,193,195,1)',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: '100%',
    // height: '100%',
    // color: 'black',
    // whiteSpace: 'nowrap',
    // borderRadius: '50% 50% 50% 0',
    // backgroundColor: '#52af77',
    // border: 'solid',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
  '& .MuiSlider-valueLabelLabel': {
    lineHeight: 1,
    fontSize: 12,
    background: 'unset',
    padding: 7,
    width: '100%',
    height: '100%',
    backgroundColor: themeColor,
    // backgroundColor: '#52af77',
    border: 'solid',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
})

export default CustomSlider