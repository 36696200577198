/* eslint-disable max-len */
import { Typography, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { v4 } from 'uuid'
import CustomButton from '../../components/CustomButton'
import { SERVER_URL } from '../../consts'
import { loanContext } from './LoanJourney'

const loanDocumentUrl = 'https://signnow.com/s/9BtfTZYK'

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    textAlign: 'center',
    padding: spacing(3),
  },
}), { name: 'JourneyFinish' })

const JourneyFinish = (): JSX.Element => {
  const classes = useStyles()

  const {
    setOpen,
    firstName,
    lastName,
    phone,
  } = useContext(loanContext)

  const [loanId] = useState(() => v4().substring(0, 14))

  useEffect(() => {
    const data = {
      firstName,
      lastName,
      loanId,
      phoneNumber: `+972${phone}`,
    }

    fetch(`${SERVER_URL}/journey/finish`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
  }, [])

  const handleClickContinue = useCallback((): void => {

    setOpen(false)
  }, [setOpen])

  return (
    <div className={`${classes.root} rtl`}>
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, }}>
        <div>
          <Typography variant="h5" fontFamily="Rubik,sans-serif">
            מזהה הלוואה:
          </Typography>
          <Typography
            variant="h6"
            fontFamily="Rubik,sans-serif"
            fontWeight='bold'
            letterSpacing={1}
            sx={{ fontSize: 16, paddingTop: 1, border: '#e8e3e3 solid', alignSelf: 'center', padding: 1 }}
          >
            ${loanId}
          </Typography>
        </div>
        <div style={{ paddingTop: 10 }}>
          <Typography variant="h6" fontFamily="Rubik,sans-serif" sx={{ fontSize: 16, paddingTop: 1 }}>
            זכאותך להלוואה תקפה לארבעת הימים הבאים,
          </Typography>
          <Typography variant="h6" fontFamily="Rubik,sans-serif" sx={{ fontSize: 16, paddingTop: 1 }}>
            נא הצג את מזהה ההלוואה עם מסמך מזהה בתוקף לנותן השירות.
          </Typography>
        </div>
        <div>
          <div
            style={{ paddingTop: 50, }}
          >
            <Typography
              color='blue'
              sx={{ border: 'solid', padding: 1, fontSize: 18, cursor: 'pointer', }}
              onClick={() => window.open(loanDocumentUrl, '_blank')}
            >
              לפרטי ההלוואה וחתימה לחצ/י כאן
            </Typography>
          </div>
        </div>
      </div>
      <CustomButton onClick={handleClickContinue}>
        סיום
      </CustomButton>
    </div>
  )
}

export default JourneyFinish