import { Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import CustomButton from '../../components/CustomButton'
import CustomDivider from '../../components/generic/CustomDivider'
import { BackButtonStyle } from '../../styles'

interface Props {
  closeDialog: () => void
  cancelCloseDialog: () => void
}
const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    padding: spacing(3.5),
    textAlign: 'center',
    height: '100%',
    // flex: 1,
  },
}), { name: 'BeforeExitDialog' })

const BeforeExitDialog = (props: Props): JSX.Element => {
  const classes = useStyles()

  const {
    closeDialog,
    cancelCloseDialog,
  } = props

  return (
    <div className={classes.root} dir='rtl'>
      <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'start', padding: 12 }}>
        <Typography variant='h6'>האם אתה בטוח שברצונך לצאת?</Typography>
        <Typography variant='subtitle2' sx={{ padding: 2, color: 'gray' }}>המידע שבתהליך ימחק לצמיתות</Typography>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', flex: 2, justifyContent: 'end' }}>
        <CustomButton onClick={closeDialog} style={BackButtonStyle} >
          כן
        </CustomButton>
        <CustomDivider title='או' />
        <CustomButton onClick={cancelCloseDialog}>
          ביטול
        </CustomButton>
      </div>
    </div>
  )
}

export default BeforeExitDialog
