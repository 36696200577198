export const plans = [
  {
    title: 'מסלול מהיר',
    monthlyPayment: '35',
    period: '12',
    loanAmount: '400',
    TotalRepaymentAmount: '423',
    serviceCommission: '4.68',
    interest: '9.1',
    key: 1,
  },
  {
    title: 'מסלול רגוע',
    monthlyPayment: '19',
    period: '24',
    loanAmount: '400',
    TotalRepaymentAmount: '440',
    serviceCommission: '4.68',
    interest: '10',
    key: 2,
  },
  {
    title: 'מסלול ארוך טווח',
    monthlyPayment: '13',
    period: '36',
    loanAmount: '400',
    TotalRepaymentAmount: '450',
    serviceCommission: '4.68',
    interest: '10.5',
    key: 3,
  },
]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getPlans = (loanAmount: string, loanPeriod: string): any => {

  // const {
  //   amount: loanAmount,
  //   term: loanPeriod,
  // } = JSON.parse(localStorage.getItem('loan_params') || JSON.stringify({}))

  // TODO: get plans from db here

  if (!loanAmount || !loanPeriod) {
    return plans
  }

  const newPlans = []

  if (loanPeriod !== '36' && loanPeriod !== '24' && loanPeriod !== '12') {    
    const interestAmount = (Number(loanAmount) / 100) * 8

    newPlans.push({
      title: 'מסלול מותאם אישית',
      monthlyPayment: Math.round((Number(loanAmount) + interestAmount) / Number(loanPeriod)),
      period: loanPeriod,
      loanAmount,
      TotalRepaymentAmount: Number(loanAmount) + interestAmount,
      serviceCommission: '4.68',
      interest: '8',
      key: 0,
    })
  }

  plans.forEach((plan) => {
    const interestAmount = (Number(loanAmount) / 100) * Number(plan.interest)

    plan.loanAmount = loanAmount
    plan.TotalRepaymentAmount = (Number(loanAmount) + interestAmount).toString()
    plan.monthlyPayment = Math.round(Number(plan.TotalRepaymentAmount) / Number(plan.period)).toString()

    newPlans.push(plan)
  })

  return newPlans
}
