const lendingRates = [
  {
    minMonths: 12,
    maxMonths: 24,
    apy: 7,
  },
  {
    minMonths: 24,
    maxMonths: 36,
    apy: 8,
  },
  {
    minMonths: 36,
    maxMonths: 48,
    apy: 9,
  },
  {
    minMonths: 48,
    maxMonths: 60,
    apy: 10,
  },
  {
    minMonths: 60,
    maxMonths: 61,
    apy: 11,
  },
]

export default lendingRates
