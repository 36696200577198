// import React from 'react'
import { Button } from '@mui/material'
import { styled } from '@mui/system'
import getQueryStringValue from '../utils/getQueryStringValue'

const themeColor = getQueryStringValue('themeColor')

const CustomButton = styled(Button)({
  backgroundColor: themeColor,
  color: 'white',
  fontWeight: 'bold',
  height: 50,
  fontSize: 18,

  '&:hover': {
    backgroundColor: themeColor,
    opacity: 0.6,
  },
})

export default CustomButton