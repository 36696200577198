import React from 'react'
import { makeStyles } from '@mui/styles'

// types
type Props = {
  data: {
    animation: string,
    top: string,
    left: string,
    animationDirection?: string,
  },
  img: string,
}

// style
const useStyles = makeStyles(() => ({
  floaty: {
    position: 'absolute',
    width: 25,
    zIndex: -1,
  },
}), { name: 'Error' })

const Floaty = ({ data, img }: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <img src={img} alt="floaty-img" className={classes.floaty} style={data} />
  )
}

export default Floaty