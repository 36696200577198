/* eslint-disable max-len */
import CreditScore from '@mui/icons-material/CreditScore'
import Numbers from '@mui/icons-material/Numbers'
import { FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, Select, TextField, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useContext } from 'react'
import CustomButton from '../../components/CustomButton'
import loanTypes from '../../data/loanTypes'
import { getPlans } from '../../data/plans'
// import addCommas from '../../functions/addCommas'
import { loanContext } from './LoanJourney'

const useStyles = makeStyles(({ spacing }: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    textAlign: 'center',
    padding: spacing(3),
  },
  titleContainer: {
  },
  title: {
    paddingBottom: spacing(3),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingBottom: spacing(2),
    placeContent: 'space-between',
  },
  // errorMessageContainer: {
  //   display: 'flex',
  //   flex: 1,
  //   alignSelf: 'end'
  // },
  textFiled: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  },
}), { name: 'PersonalDetails' })


const LoanDetails = (): JSX.Element => {
  const classes = useStyles()

  const {
    setStage,
    loanAmount,
    setLoanAmount,
    loanPeriod,
    setLoanPeriod,
    setPlans,
    setLoanPlan,
    isLoanConfirmed,
    setIsLoanConfirmed,
    loanType,
    setLoanType,
  } = useContext(loanContext)

  const handleClickContinue = (): void => {
    if (loanAmount && loanPeriod) {
      const valueWithoutComma = loanAmount.replaceAll(',', '')

      setLoanAmount(valueWithoutComma)

      const plans = getPlans(valueWithoutComma, loanPeriod)

      setPlans(plans)
      setLoanPlan(plans[0])
      if (isLoanConfirmed) {
        setIsLoanConfirmed(false)
        setStage(6) // to ChoosePlan
      }
      else {
        setStage((prev) => prev + 1)
        setIsLoanConfirmed(true)
      }
    }
  }

  const handleChangeLoanAmount = ((value: string): void => {
    if (value.match(/[^\d,]+/)) { // check letters
      return
    }

    const valueWithoutComma = value.replaceAll(',', '')

    // setLoanAmount(addCommas(valueWithoutComma))
    setLoanAmount(Number(valueWithoutComma).toLocaleString())
  })

  return (
    <div className={`${classes.root} rtl`} >
      <div className={classes.titleContainer}>
        <Typography variant='h6' className={classes.title}>
          ללקיחת הלוואה מיידית
        </Typography>
        <Typography variant='h6' className={classes.title}>
          בתשלומים, בקלות ובמהירות!
        </Typography>
      </div>
      <form className={classes.form} onSubmit={handleClickContinue}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <FormControl fullWidth>
            <InputLabel id='loan-type-select-label' >סוג ההלוואה</InputLabel>
            <Select
              labelId="loan-type-select-label"
              value={loanType}
              label='סוג ההלוואה'
              placeholder='סוג ההלוואה'
              onChange={({ target }) => setLoanType(target.value)}
              sx={{ textAlign: 'right' }}
            >
              {
                loanTypes.map((type, index) => <MenuItem sx={{ placeContent: 'end' }} key={index} value={type}>{type}</MenuItem>)
              }
            </Select>
          </FormControl>
          <TextField
            required
            value={loanAmount}
            onChange={({ target }) => handleChangeLoanAmount(target.value)}
            className={classes.textFiled}
            label='גובה ההלואה המבוקשת בש״ח'
            margin="normal"
            InputLabelProps={{
              sx: {
                textAlign: 'center'
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <CreditScore />
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="end">
                  <Typography variant='h6'>
                    ₪
                  </Typography>
                </InputAdornment>
              )
            }}
          />
          <TextField
            required
            value={loanPeriod}
            onChange={(({ target }) => setLoanPeriod(target.value && Number(target.value) > 100 ? '100' : target.value))}
            className={classes.textFiled}
            label='מספר התשלומים'
            margin="normal"
            type='number'
            InputLabelProps={{
              sx: {
                textAlign: 'center'
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Numbers />
                </InputAdornment>
              ),
            }}
          />
          <FormHelperText sx={{ display: 'flex' }}>
            עד 100 תשלומים
          </FormHelperText>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <CustomButton onClick={handleClickContinue} type='submit'>
            המשך
          </CustomButton>
        </div>
      </form >
    </div >
  )
}

export default LoanDetails