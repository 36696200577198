import { WebAuth } from 'auth0-js'
import { AUTH0_DOMAIN, AUTH0_CLIENT_ID } from '../consts'

const webAuth = new WebAuth({
  domain: AUTH0_DOMAIN,
  clientID: AUTH0_CLIENT_ID,
  // responseMode: 'query',
  responseType: 'code id_token token',
  redirectUri: `${window.location.origin}?otp=true`
})

export {
  webAuth,
}
